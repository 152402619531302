import { graphql } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import ArticleCard from "../components/Cards/ArticleCard"
import Pagination from "../components/Pagination/Pagination"
import Seo from "../components/seo"
import ArchiveLayout from "../layouts/ArchiveLayout"

const Grid = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
`

function CategoryArchive({
  data: { allWpPost, wpCategory },
  pageContext: { nextPagePath, previousPagePath },
}) {
  const { nodes: posts } = allWpPost
  const { name } = wpCategory

  return (
    <ArchiveLayout>
      <Seo
        title={wpCategory.seo.title}
        description={wpCategory.seo.metaDesc}
        twitterTitle={wpCategory.seo.twitterTitle}
        twitterDescription={wpCategory.seo.twitterDescription}
        twitterImage={wpCategory.seo.twitterImage}
        opengraphTitle={wpCategory.seo.opengraphTitle}
        opengraphDescription={wpCategory.seo.opengraphDescription}
        opengraphType={wpCategory.seo.opengraphType}
        opengraphImage={wpCategory.seo.opengraphImage}
        schema={wpCategory.seo.schema}
        metaRobotsNoindex={wpCategory.seo.metaRobotsNoindex}
        metaRobotsNofollow={wpCategory.seo.metaRobotsNofollow}
        canonical={wpCategory.seo.canonical}
      />
      <h1>{name}</h1>
      <Grid>
        {posts.map(post => (
          <ArticleCard key={post.id} {...post} />
        ))}
      </Grid>
      <Pagination
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
      />
    </ArchiveLayout>
  )
}

export default CategoryArchive

export const pageQuery = graphql`
  query BlogPostByCategoryId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $offset: Int!
    $postsPerPage: Int!
    $categoryId: String!
  ) {
    wpCategory(id: { eq: $categoryId }) {
      name
      description
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        schema {
          raw
        }
      }
    }
    # selecting all posts by category id
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
      }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
            date
          }
        }
        id
        uri
        title
        databaseId
        tags {
          nodes {
            name
            slug
            uri
          }
        }
      }
    }
  }
`
